<template>
  <div class="w-100">
    <b-row>
      <b-col id="tree-key">
        <h4>Key - Coloured by Validity</h4>
        <h6>
          <b-badge style="background-color: #D8E9D2; color: #2d2d2d">
            Valid
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFF2CB; color: #2d2d2d">
            Minor Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFE497; color: #2d2d2d">
            Moderate Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #F3CBCB; color: #2d2d2d">
            Major Uncertainty
          </b-badge>&nbsp;
          <b-badge style="background-color: #FFFFFF; color: #2d2d2d">
            Refinement
          </b-badge>
        </h6>
      </b-col>
      <b-col>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="refreshTree"
          >
            <feather-icon icon="RefreshCcwIcon" />
            Refresh
          </b-button>
        </b-button-group>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="showAllLinks"
          >
            <feather-icon icon="LinkIcon" class="mr-25" />
            <span v-if="showLinks">Hide X-Refs</span>
            <span v-else>Show X-Refs</span>
          </b-button>
        </b-button-group>
        <b-button-group class="float-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon ml-1"
            @click="showHideTiming"
          >
            <feather-icon icon="ClockIcon" class="mr-25" />
            <span v-if="showTiming">Hide Timing</span>
            <span v-else>Show Timing</span>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div>
          <!-- BT View Graph -->
          <behaviour-tree-viewer
            :refresh-variable="refreshVar"
            :show-links="showLinks"
            :show-timing="showTiming"
            :update-object="updateObject"
            @add-node="createBehaviour()"
            @clickNode="openSideMenu"
            @bulkSelect="openBulkMenu"
          />
        </div>
      </b-col>

      <!-- Details Panel -->
      <b-sidebar
        id="sidebar-1"
        title="Behaviour Node"
        bg-variant="white"
        shadow="true"
        width="35vw"
        right
        :backdrop="false"
        :visible="sideMenuOpened"
        @hidden="closeSideMenu"
        @clickNode="openSideMenu"
      >
        <behaviour-sidebar
          class="mx-2"
          :side-menu-data-loading="sideMenuDataLoading"
          @updated="updatedNode"
        />
      </b-sidebar>

      <!-- Bulk Menu -->
      <b-sidebar
        id="sidebar-2"
        title="Bulk Association"
        bg-variant="white"
        shadow="true"
        width="35vw"
        right
        backdrop
        :visible="bulkMenuOpened"
        @hidden="closeBulkMenu"
        @clickNode="openBulkMenu"
      >
        <BulkOperationsSidebar
          class="mx-2"
          :side-menu-data-loading="sideMenuDataLoading"
          :nodes="bulkNodes"
        />
      </b-sidebar>
    </b-row>
    <!--<add-behaviour />-->
  </div>
</template>

<script>
import BehaviourTreeViewer from '@/views/Behaviour/BehaviourTreeViewer2.vue'
import { mapState } from 'vuex'
import AddBehaviour from '@/components/Behaviours/Modals/AddBehaviour.vue'
import BehaviourSidebar from '@/views/Behaviour/BehaviourSidebar.vue'
import BulkOperationsSidebar from '@/views/Behaviour/BulkOperationsSidebar.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'BehaviourTree',
  components: {
    BehaviourSidebar,
    BulkOperationsSidebar,
    BehaviourTreeViewer,
    // AddBehaviour,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      behaviourTrees: [],
      sideMenuOpened: false,
      sideMenuDataLoading: true,
      refreshVar: 0,
      updateObject: null,
      showLinks: false,
      showTiming: false,
      selectedNode: null,
      bulkMenuOpened: false,
      bulkNodes: [],
    }
  },
  computed: {
    ...mapState({
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      behaviourViews: state => state.behaviours.behaviourViews,
    }),
    bt_opts() {
      return this.behaviourViews.map(x => ({ value: x.id, text: x.name }))
    },
  },
  watch: {
    selected_bt(newVal) {
      this.selectedTree = newVal.properties
      this.selectedTreeId = newVal.properties.id
    },
  },
  mounted() {
    this.$route.meta.pageTitle = this.selected_bt.properties.name
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  beforeDestroy() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.commit('app/CLEAR_DYNAMIC_PAGE_TITLE')
  },
  methods: {
    createBehaviour() {
      // this.$bvModal.show('add-behaviour-modal')
    },
    openSideMenu(nodeId) {
      this.selectedNode = nodeId
      this.bulkNodes = [nodeId]
      this.sideMenuDataLoading = true
      this.sideMenuOpened = true
      this.$store.dispatch('behaviours/selectBehaviourNode', nodeId)
        .then(() => {
          setTimeout(() => {
            this.sideMenuDataLoading = false
          }, 50)
        })
        .catch(() => {
          this.sideMenuDataLoading = true
        })
    },
    openBulkMenu(nodes) {
      console.log('Selected Nodes: ', nodes)
      this.bulkNodes = nodes
      this.bulkMenuOpened = true
    },
    closeSideMenu() {
      this.sideMenuOpened = false
    },
    closeBulkMenu() {
      this.bulkMenuOpened = false
    },
    refreshTree() {
      this.refreshVar += 1
    },
    showAllLinks() {
      this.showLinks = !this.showLinks
    },
    showHideTiming() {
      this.showTiming = !this.showTiming
      console.log('showTiming set to: ', this.showTiming)
    },
    updatedNode(data) {
      this.closeSideMenu()
      console.log('Updated Object: ', data)
      this.updateObject = data
    },
  },
}
</script>
